@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


*{
margin: 0
}

.App {
	text-align: center;
  font-family: 'Roboto Condensed', serif;
}


.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #09d3ac;
}

.user-cards {
	display: flex;
	flex-wrap: wrap;
	margin: 3rem;
	justify-content: space-around;
}

.expats {
  margin-top: 0px!important;
  padding-top: 30px
}

.user-card-container {
	display: flex;

	margin-bottom: 2rem;
}
.user-card-img {
	width: 100%;
	border-radius: 5px;
}
/* 
.styled-card{
  background: #e5e5e5;
} */

/* FONT OVERRIDES */
h1,
h2,
p {
	font-family: 'Roboto Condensed', serif;
}

/* pushes down Users h1 in Browse */
.user-card-parent {
	background-image: url(https://images.unsplash.com/photo-1436775423025-9233051c49d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80);
  background-repeat: no-repeat;
  background-size: cover;
}

.user-card-parent h1 {
	margin: 3%;
	font-size: 3rem;
}

/* MEDIA QUERIES FOR MOBILE */
@media screen and (max-width: 500px) {
	.login-btn {
		display: none;
	}
	.member-txt {
		display: none;
	}
}

/* Traveler.js */

.main-pic-traveler {
	width: 70%;
}


.traveler-trips-container {
  display: flex;
  justify-content: space-around;
}

.traveler-trip-div {
  font-size: 200%
}

/* Profile.js */
.profile-trip-div {
	/* border: 1px solid black; */
	font-size: rem;
}
/* Trip Component*/
.add-trip {
  background: rgb(230, 230, 230);
  width: 100px;
  color: black;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
}

.header-container {
  margin: 30px 0;
}
.header-container h1 {
  margin: 0;
}

.private-button-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-bottom: 20px;
}
.private-button {
  width: 100px;
  border-radius: 5px;
  color: white;
}

#edit {
  background: #d36d00;
}

#delete {
  background: red;
}

/* from ryan */
.private-trip-header-div{
  height: 40vh;
  overflow: hidden;
  position: relative
}
.private-trip-header-img {
  position: absolute;
  top:-100%; left:0; right: 0; bottom:-90%;
  margin: auto;
  width: 90%;
}

.trip-sub-nav {

	width: 93%;
	margin: 10px auto 0;
	display: flex;
	justify-content: center;
	border-radius: 5px;
}

.public-trip-header-div {
	height: 40vh;
	overflow: hidden;
	position: relative;
}
.public-trip-header-img {
  position: absolute;
  top:-100%; left:0; right: 0; bottom:-90%;
  margin: auto;
  width: 90%;
}

.public-trip-content {
  /* background: rgb(238, 238, 238); */
  /* border: 1px solid lightgrey; */
  border-top: none;
  border-radius: 5px;
  width: 93%;
  margin: 0 auto;
  font-size: 125%;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.blog-container {
  padding: 1% 10%;
  border: 1px solid grey;
}

.trip-sub-nav-tab {

  /* background: rgb(238, 238, 238); */
  border: 1px solid lightgrey;
  /* border-bottom:  rgb(238, 238, 238); */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0 2%;
  padding: 15px;
  font-size: 150%;
  color: black;
  width: 200px;
}


.image-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.onclick-name{
	background: rgb(238, 238, 238);
	border: 1px solid lightgrey;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 10px;
	font-size: 90%;
	color: black;
	border-radius: 5px;
	margin: .8rem;
	
	
}
 .onclick-name:hover {
	color: #4183c4;
  }

/* Blog */

/* Footer */

.footer-div {
  width: 52%;
  display: flex;
  justify-content: space-between;
}

.footer-bar {
  height: 18vh;
}

.footer-div h6{
margin-left: 20px;
}

.blog-name {
  color: grey;
}

/* New Trip */
.newTrip {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0 auto;
  padding: 20px;
 
  background: rgb(231, 231, 231);
  border: 1px solid lightgrey;
  border-radius: 15px
}

.newTripButton {
  background: lightgrey;
  border: 1px solid rgb(158, 158, 158);
}

.newTripItem {
  margin: 10px 0;
}

.newTripPage {
  height: 90vh;
  padding-top:200px;
  background-image: url(https://images.unsplash.com/photo-1568478009690-45fdbd81f32d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80);
  background-repeat: no-repeat;
  background-size: cover;
}
